import React from 'react';
import {
  Added,
  Fixed,
  Improved,
  Link,
  List,
  PageWithSubNav,
  Paragraph,
  PlatformHeader,
} from '../../../../components';
import styles from '../../index.module.scss';

const IndexPage = () => {
  return (
    <PageWithSubNav pageType="design" title="June 2018" subnav="release-notes">
      <div id="June2018" className={styles.release}>
        <Paragraph className="uni-margin--one--vertical">
          Did someone say three new components? The lookup multi-select is
          finally here! And gracious enough to share the spotlight with action
          list and tooltip. A new icon <em>label</em> was added to complement
          icon-dense interfaces, and new props were published for a few
          components (thanks to a number of helping hands). We even fixed some
          things to make Uniform look better in IE11—because why not? The full
          rundown is below.
        </Paragraph>

        <div className={styles.releaseInfo}>
          <div className={styles.platform}>
            <PlatformHeader libName="Docs" />

            <div className={styles.changesList}>
              <Added>
                <Link href="/components/action-list" isDesignCodeLink>
                  Action list guidelines
                </Link>{' '}
                for interfaces without much room.
              </Added>
              <Added>
                Everything for{' '}
                <Link
                  href="/components/selects/lookup-multi-select"
                  isDesignCodeLink>
                  lookup multi-select
                </Link>
                .
              </Added>
              <Added>
                <Link href="/components/tooltip" isDesignCodeLink>
                  Tooltip details
                </Link>{' '}
                to help you help the user.
              </Added>
              <Added>Back to Top links on all pages.</Added>
              <Improved>
                iOS and macOS guidelines, now combined under the Apple tab.
              </Improved>
            </div>
          </div>

          <div className={styles.platform}>
            <PlatformHeader
              libName="Components"
              version="3.10.0"
              versionUrl="https://github.com/hudl/uniform-ui-components/releases/tag/v3.10.0"
            />

            <div className={styles.changesList}>
              <Added>
                A shiny new{' '}
                <Link href="/components/action-list" isDesignCodeLink>
                  action list
                </Link>{' '}
                component.
              </Added>
              <Added>
                The long-awaited{' '}
                <Link
                  href="/components/selects/lookup-multi-select"
                  isDesignCodeLink>
                  lookup multi-select
                </Link>
                . (Huge hat tip to Paul, Derek and Nick.)
              </Added>
              <Added>
                A super useful{' '}
                <Link href="/components/tooltip" isDesignCodeLink>
                  tooltip
                </Link>
                .
              </Added>
              <Added>
                The <code>label</code> and <code>labelPosition</code> props for{' '}
                <Link href="/components/icon/code">icons</Link>.
              </Added>
              <Added>
                A <code>parentSelector</code> prop for{' '}
                <Link href="/components/dialogs/modal/code">modals</Link>:
              </Added>
              <Added>
                New props to further customize the{' '}
                <Link href="/components/selects/lookup-select/code">
                  lookup select
                </Link>
                :
                <List>
                  <li>
                    <code>customOption</code>
                  </li>
                  <li>
                    <code>onSearchChange</code>
                  </li>
                  <li>
                    <code>preserveSelectionOnBackspace</code> (Thanks, Paul!)
                  </li>
                </List>
              </Added>
              <Added>
                The <code>minHeight</code> prop for{' '}
                <Link href="/components/forms/text-area/code?web#minHeight">
                  text area
                </Link>
                . (Thanks, Derek!)
              </Added>
              <Fixed>
                An issue where a console error would appear when the toast’s{' '}
                <code>onDismiss</code> prop was undefined. (Thanks, Kim!)
              </Fixed>
            </div>
          </div>

          <div className={styles.platform}>
            <PlatformHeader
              libName="CSS"
              version="1.7.0"
              versionUrl="https://github.com/hudl/uniform-ui-css/releases/tag/v1.7.0"
            />

            <div className={styles.changesList}>
              <Added>
                Styling for the{' '}
                <Link href="/components/action-list" isDesignCodeLink>
                  action list
                </Link>
                .
              </Added>
              <Added>
                Styling for the{' '}
                <Link
                  href="/components/selects/lookup-multi-select"
                  isDesignCodeLink>
                  lookup multi-select
                </Link>
                .
              </Added>
              <Added>
                Styling for{' '}
                <Link href="/components/tooltip" isDesignCodeLink>
                  tooltips
                </Link>
                .
              </Added>
              <Fixed>
                A vertical alignment issue caused by flex for{' '}
                <Link href="/components/buttons/button" isDesignCodeLink>
                  buttons
                </Link>{' '}
                in IE11.
              </Fixed>
              <Fixed>
                A background color mishap with unselected checkboxes/radios in
                dark environments. (Thanks, Craig!)
              </Fixed>
            </div>
          </div>

          <div className={styles.platform}>
            <PlatformHeader libName="Sketch Library" />

            <div className={styles.changesList}>
              <Added>Our new action list component.</Added>
              <Added>The pills for the new multi-select.</Added>
              <Added>The tooltip, obviously.</Added>
              <Added>Icon labels, as well.</Added>
              <Fixed>
                A corner-radius issue on the Restricted icon that appeared when
                resizing to something smaller.
              </Fixed>
            </div>
          </div>
        </div>
      </div>
    </PageWithSubNav>
  );
};

export default IndexPage;
